import { Role } from "@@types/roles";
import { lazy } from "react";

const TextWhatsapp = lazy(() => import("./whatsapp/text/TextMessages"));
const ContactWhatsapp = lazy(
  () => import("./whatsapp/contact/ContactMessages")
);
const ImageWhatsapp = lazy(() => import("./whatsapp/image/ImageMessages"));
const VideoWhatsapp = lazy(() => import("./whatsapp/video/VideoMessages"));
const DocumentWhatsapp = lazy(
  () => import("./whatsapp/document/DocumentMessages")
);
const LocationWhatsapp = lazy(
  () => import("./whatsapp/location/LoactionMessages")
);
const AudioWhatsapp = lazy(() => import("./whatsapp/audio/AudioMessages"));
const InteractiveWhatsapp = lazy(
  () => import("./whatsapp/interactive/InteractiveMessages")
);
const TemplateWhatsapp = lazy(
  () => import("./whatsapp/template/TemplateMessages")
);

const TextMessenger = lazy(() => import("./messenger/text/TextMessages"));
const ImageMessenger = lazy(() => import("./messenger/image/ImageMessages"));
const VideoMessenger = lazy(() => import("./messenger/video/VideoMessages"));
const DocumentMessenger = lazy(
  () => import("./messenger/document/DocumentMessages")
);
const AudioMessenger = lazy(() => import("./messenger/audio/AudioMessages"));
/**
 * The chat app config.
 */
const CompanyMessagesAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "companyMessages/messenger/text",
      element: <TextMessenger />,
      auth: [Role.MESSAGES_COMPANY],
    },
    {
      path: "companyMessages/messenger/image",
      element: <ImageMessenger />,
      auth: [Role.MESSAGES_COMPANY],
    },
    {
      path: "companyMessages/messenger/video",
      element: <VideoMessenger />,
      auth: [Role.MESSAGES_COMPANY],
    },
    {
      path: "companyMessages/messenger/document",
      element: <DocumentMessenger />,
      auth: [Role.MESSAGES_COMPANY],
    },
    {
      path: "companyMessages/messenger/audio",
      element: <AudioMessenger />,
      auth: [Role.MESSAGES_COMPANY],
    },
    {
      path: "companyMessages/whatsapp/text",
      element: <TextWhatsapp />,
      auth: [Role.MESSAGES_COMPANY],
    },
    {
      path: "companyMessages/whatsapp/audio",
      element: <AudioWhatsapp />,
      auth: [Role.MESSAGES_COMPANY],
    },

    {
      path: "companyMessages/whatsapp/contact",
      element: <ContactWhatsapp />,
      auth: [Role.MESSAGES_COMPANY],
    },
    {
      path: "companyMessages/whatsapp/image",
      element: <ImageWhatsapp />,
      auth: [Role.MESSAGES_COMPANY],
    },
    {
      path: "companyMessages/whatsapp/video",
      element: <VideoWhatsapp />,
      auth: [Role.MESSAGES_COMPANY],
    },
    {
      path: "companyMessages/whatsapp/document",
      element: <DocumentWhatsapp />,
      auth: [Role.MESSAGES_COMPANY],
    },
    {
      path: "companyMessages/whatsapp/location",
      element: <LocationWhatsapp />,
      auth: [Role.MESSAGES_COMPANY],
    },
    {
      path: "companyMessages/whatsapp/interactive",
      element: <InteractiveWhatsapp />,
      auth: [Role.MESSAGES_COMPANY],
    },
    {
      path: "companyMessages/whatsapp/template",
      element: <TemplateWhatsapp />,
      auth: [Role.MESSAGES_COMPANY],
    },
    // {
    //   path: "companyMessages/FlowEditor",
    //   element: <FlowEditor />,
    //   auth: [Role.MESSAGES_COMPANY],
    // },
  ],
};

export default CompanyMessagesAppConfig;
